<template>
  <div class="py-10">
    <header>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight text-gray-900">
          Who are Buy Your Car Online?
        </h1>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="px-4 py-8 sm:px-0">
          <div class="rounded-lg bg-white px-8 py-6">
            <p>We’re changing the way people buy used cars by providing better selection, value, quality, convenience and peace of mind. With Buy Your Car Online, you can browse and buy any of our cars online and have it delivered to your door at no extra cost.</p>
            <p class="mt-6">We only buy the best cars in the market and we thoroughly inspect and fully recondition each one. Our mechanics’ expertise combined with our comprehensive checks and customer support team means that you can feel 100% confident when it comes to buying a Buy Your Car Online purchase</p>
            <h2 class="text-2xl mt-6 font-bold mb-4">High-quality cars handpicked for you</h2>
            <p class="mt-6">We’re incredibly selective about the cars we buy, purchasing only the best used cars in the market. From family SUVs to sporty hatches and luxury drives, we’ve got a wide range of high-quality cars in stock from the most popular makes and models. There’s no need to haggle on price either. All our prices are fixed with no hidden surprises or extra costs.</p>
            <h2 class="text-2xl mt-6 font-bold mb-4">150+ point inspection
</h2>
            <p class="mt-6">Every car we sell has passed a thorough 150+ point inspection before delivery. Our mechanics check everything from the lights, windscreen wipers and radio to the sat-nav, cooling system and more. We spend hundreds of pounds reconditioning each car before we sell it, so if anything needs repairing, we’ve got it sorted</p>
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "About"
};
</script>

<style>

h2 {
  color: #2e2954;
}

</style>
